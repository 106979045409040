<template>
    <div class="mcontainer pt-0 pt-lg-3 pt-xl-5">
        <div class="profile is_group">
            <div class="profiles_banner">
                <img :src="(detail && detail.banner) ? detail.banner : require('@/assets/images/avatars/profile-cover.png')" alt="">
                <div class="change-banner" @click="$refs.banner.click()" v-if="detail && detail.is_owner || detail.is_admin">
                    <Icon icon="uil:camera-change" class="d-inline mr-2" />
                    <input type="file" ref="banner" @change="onBannerChange" class="d-none">
                </div>
            </div>
            <div class="profiles_content">
                <img :src="(detail && detail.logo) ? detail.logo : require('@/assets/images/avatars/avatar-1.jpg')" class="w-10 h-10 rounded-full">
                <div class="profile_info" style="margin-left: 20px;">
                    <h1>{{ detail ? detail.title : '' }}</h1>
                    <p><span class="capitalize">{{ detail ? detail.privacy : '' }}</span> group ·  {{ detail ? detail.total_member : '' }} members</p>
                </div>
                <div class="flex items-center space-x-4">
                    <div class="flex items-center -space-x-4">
                        <b-input-group class="input-search">
                            <b-input-group-prepend is-text>
                                <Icon icon="bi:search" class="d-inline mr-2" />
                            </b-input-group-prepend>
                            <b-form-input v-model="contentSearch.q" @keyup.enter="searchGroup" aria-label="Text input" placeholder="Search"></b-form-input>
                        </b-input-group>
                    </div>
                    <b-dropdown class="flex items-center" variant="primary" no-caret>
                        <template #button-content>
                            <Icon icon="fa-solid:sort-amount-up" class="d-inline md:mr-2" /><span class="md:inline hidden">Sort</span>
                        </template>
                        <b-dropdown-item @click.prevent="setOrder('newest')">Newest</b-dropdown-item>
                        <b-dropdown-item @click.prevent="setOrder('oldest')">Oldest</b-dropdown-item>
                    </b-dropdown>
                    <button @click="deleteGroup" class="btn btn-danger" v-if="datauser.is_admin === 1"><Icon icon="bx:bx-trash" class="d-inline md:mr-2" /><span class="md:inline hidden">Delete</span></button>
                    <b-dropdown v-if="detail && detail.is_member || detail.is_owner" class="flex items-center" variant="primary" no-caret>
                        <template #button-content>
                            <Icon icon="akar-icons:more-vertical" class="d-inline md:mr-2" /><span class="md:inline hidden">More</span>
                        </template>
                        <b-dropdown-item v-if="detail && !detail.is_member" @click="joinGroup">Join</b-dropdown-item>
                        <b-dropdown-item v-if="detail && (detail.is_admin || detail.is_owner || detail.privacy === 'public')" @click="inviteMemberShow">Invite Member</b-dropdown-item>
                        <b-dropdown-item v-if="detail && detail.is_admin || detail.is_owner" :to="{ name: 'GroupSetting' }">Pengaturan</b-dropdown-item>
                        <b-dropdown-item @click="reportGroup">Laporkan</b-dropdown-item>
                        <b-dropdown-item v-if="detail && detail.is_member && !detail.is_admin" @click="leaveGroup">Keluar</b-dropdown-item>
                        <b-dropdown-item v-if="detail && detail.is_admin || detail.is_owner" @click="deleteGroup">Hapus</b-dropdown-item>
                    </b-dropdown>
                    <button @click="actionJoin" class="bg-blue-600 flex flex-1 h-8 items-center justify-center rounded-md text-white capitalize" style="min-width: 100px;" v-else>Join</button>
                </div>
            </div>
        </div>

        <div class="md:flex md:space-x-6">
            <div class="space-y-5 flex-shrink-0 md:w-8/12">
                <nav class="responsive-nav border-b">
                    <ul class="px-0">
                        <li v-for="item in groupNavigations" :key="item.route" :class="activeNavigation === item.route ? 'active' : ''">
                            <a v-if="detail && !detail.is_owner && !detail.verification && detail.privacy !== 'public'" href="" @click.prevent class="lg:px-2 disabled cursor-not-allowed" disabled>
                                {{ item.label }}
                            </a>
                            <router-link v-else :to="{ name: item.route }" class="lg:px-2">
                                {{ item.label }}
                                <span v-if="(detail && detail.is_admin || detail.is_owner) && (detail.total_unapprove > 0 && item.label === 'Members')">{{ detail.total_unapprove }}</span>
                            </router-link>
                        </li>
                    </ul>
                </nav>

                <div v-if="detail && detail.verification || detail.is_owner || detail.is_admin || detail.privacy === 'public'">
                    <transition name="slide-fade" mode="out-in">
                        <router-view />
                    </transition>
                </div>

                <div v-else class="text-center py-20">
                    <empty v-if="detail.is_member" image="/images/confirmation.svg" text="Please wait for admin confirmation" />
                    <empty v-else image="/images/content-secured.svg" text="Please join group to see content" />
                </div>
            </div>
            <div class="w-full flex-shirink-0 containerSticky">
                <vue-sticky-sidebar containerSelector=".containerSticky" innerWrapperSelector=".uk-sticky" :topSpacing='100'>
                    <transition name="slide-fade" mode="out-in">
                        <div class="position-relative input-file" v-if="activeNavigation === 'GroupFile'">
                            <b-button variant="primary" class="mb-4">
                                <b-spinner v-if="uploadFilesLoading" small class="mr-2" />
                                <Icon v-else icon="bi:upload" class="d-inline mr-2" />
                                Add File
                            </b-button>
                            <input type="file" @change="onFileChange" accept="application/pdf,image/png,image/jpg,image/jpeg,image/gif,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,video/mp4,.3gp,.mp3">
                        </div>
                    </transition>
                    <div class="card p-4 mb-4 hidden d-md-block">
                        <h1 class="block text-lg font-bold"> About  </h1>
                        <p>{{ detail.description }}</p>
                        <div class="space-y-4 mt-3">
                            <div v-if="detail.privacy === 'private'" class="flex items-center space-x-3">
                                <Icon icon="uis:lock-alt" />
                                <div class="flex-1">
                                    <div class="font-semibold capitalize">Private</div>
                                </div>
                            </div>
                            <div v-if="detail.privacy === 'public'" class="flex items-center space-x-3">
                                <Icon icon="uis:lock-open-alt" />
                                <div class="flex-1">
                                    <div class="font-semibold capitalize">Public</div>
                                </div>
                            </div>
                            <div v-if="detail.privacy === 'unlisted'" class="flex items-center space-x-3">
                                <Icon icon="ci:hide" />
                                <div class="flex-1">
                                    <div class="font-semibold capitalize">Unlisted</div>
                                </div>
                            </div>
                            <div class="flex items-center space-x-3">
                                <Icon icon="bx:bx-user" />
                                <div class="flex-1">
                                    <div class="font-semibold"> {{ detail.total_member }} Members </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-sticky-sidebar>
            </div>
        </div>
        <modal name="invite-member" :height="600" :width="400">
            <div class="p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-black font-semibold dark:text-gray-100">
                        Undang Teman ke Group
                    </h5>
                    <a href="" @click.prevent="inviteMemberClose">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="currentColor"/>
                        </svg>
                    </a>
                </div>
                <div class="position-relative invite-search mt-4">
                    <span class="search-icon">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z" fill="currentColor"/>
                        </svg>
                    </span>
                    <input type="text" placeholder="Cari Teman" v-model="inviteMember.search" @keyup.enter="inviteMemberGetUsers">
                    <button class="btn btn-primary search-button" @click="inviteMemberGetUsers">
                        Cari
                    </button>
                </div>
                <hr class="my-4">
                <div class="invite-users">
                    <loading :loading="inviteMember.usersLoading" spinner-class="justify-content-center">
                        <smooth-scrollbar v-if="inviteMember.users.length > 0" style="height: 370px;">
                            <div v-for="user in inviteMember.users" :key="user.id" class="d-flex justify-content-between align-items-center pr-2">
                                <div class="d-flex my-2 align-items-center">
                                    <div class="mr-3">
                                        <img :src="user.images !== '' && user.images !== null ? user.images : require('@/assets/images/avatars/avatar-1.jpg')" class="w-16 h-16 rounded-full">
                                    </div>
                                    <div>
                                        <p class="m-0 text-black font-semibold dark:text-gray-100">
                                            {{ user.fullname }}
                                        </p>
                                        <p class="m-0">
                                            {{ user.email }}
                                        </p>
                                    </div>
                                </div>
                                <b-form-checkbox
                                    :value="true"
                                    :unchecked-value="false"
                                    @change="(value) => inviteMemberChecklistChange(user.id, value)"
                                />
                            </div>
                        </smooth-scrollbar>
                        <div v-else style="height: 370px" class="pt-5 px-3">
                            <empty text="Tidak ada teman untuk di invite ke group" />
                        </div>
                    </loading>
                </div>
                <div>
                    <button class="btn btn-primary search-button d-block w-full mt-3" @click="inviteMembers">
                        <span v-if="inviteMember.loading"><b-spinner small label="Loading..." /></span>
                        Undang Teman
                    </button>
                </div>
            </div>
        </modal>
        <modal name="report-group" :height="270" :width="400">
            <div class="p-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-black font-semibold dark:text-gray-100">
                        Laporkan Group
                    </h5>
                    <a href="" @click.prevent="closeReportGroup">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="currentColor"/>
                        </svg>
                    </a>
                </div>
                <div class="mt-3">
                    <textarea class="form-control h-32" value="" v-model="report.data.reason" type="text" autocomplete="off" placeholder="Alasan Melaporkan" required></textarea>
                </div>
                <div>
                    <button class="btn btn-primary search-button d-block w-full mt-3" @click="submitReportGroup">
                        <span v-if="report.loading"><b-spinner small label="Loading..." /></span>
                        Submit
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import axios from 'axios'
    import { Icon } from '@iconify/vue2'
    import vueStickySidebar from 'vue-sticky-sidebar'
    import { mapState, mapActions } from 'vuex'
    import Empty from '@/components/Empty.vue'
    import fileSize from 'filesize'

    export default {
        name: 'GroupDetail',
        components: {
            Icon,
            vueStickySidebar,
            Empty
        },
        computed: {
            activeNavigation () {
                return this.$route.name
            },
            ...mapState('groups', {
                detail: state => state.selected.data,
                loading: state => state.selected.loading,
                contentFilter: state => state.selected.contentFilter
            }),
            ...mapState('auth', { datauser: 'user' })
        },
        data () {
            return {
                groupNavigations: [
                    { route: 'GroupTimeline', label: 'Timeline' },
                    { route: 'GroupDiscussion', label: 'Discussion' },
                    { route: 'GroupAbout', label: 'About Group' },
                    { route: 'GroupFile', label: 'Files' },
                    { route: 'GroupMember', label: 'Members' },
                    { route: 'GroupResource', label: 'Resource' }
                ],
                uploadFilesLoading: false,
                contentSearch: {
                    q: '',
                    order: ''
                },
                inviteMember: {
                    loading: false,
                    search: '',
                    users: [],
                    usersLoading: false,
                    checkedUsers: []
                },
                report: {
                    loading: false,
                    data: {
                        reason: ''
                    }
                }
            }
        },
        created () {
            this.fetchDetailGroup()
            this.contentSearch = { ...this.$route.query }
        },
        methods: {
            ...mapActions('groups', ['show', 'delete', 'addFile', 'getAttachments', 'changeBanner', 'leave', 'getInviteMemberList']),
            fetchDetailGroup () {
                this.show(this.$route.params.code)
            },
            setOrder (order) {
                if (order) this.contentSearch.order = order
                this.searchGroup()
            },
            searchGroup () {
                this.$router.push({ name: 'GroupSearch', query: { ...this.contentSearch } })
                // if (this.$route.name !== 'GroupSearch') {
                // } else {
                //     this.$eventHub.emit('GROUP_CONTENT_SEARCH', this.contentSearch)
                // }
            },
            deleteGroup () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.delete(this.detail.id)
                            .then(() => {
                                this.$swal(
                                    'Deleted!',
                                    'Your group has been deleted.',
                                    'success'
                                ).then(() => {
                                    this.$router.push({ name: 'Group' })
                                })
                            })
                            .catch((e) => console.log(e))
                    }
                })
            },
            joinGroup () {
                //
            },
            leaveGroup () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, leave now!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.leave(this.detail.id)
                            .then(() => {
                                this.$swal(
                                    'Leave!',
                                    'You have left the group.',
                                    'success'
                                ).then(() => {
                                    this.$router.push({ name: 'Group' })
                                })
                            })
                            .catch((e) => console.log(e))
                    }
                })
            },
            onFileChange (event) {
                const maxFileUpload = process.env.VUE_APP_MAX_FILE_UPLOAD
                var uploadError = false

                if (event.target.files) {
                    event.target.files.forEach(file => {
                        if (!window.FileReader) {
                            this.$swal(
                                'Error',
                                'The file API isn\'t supported on this browser yet.',
                                'error'
                            )
                        }
                        if (file.size > maxFileUpload) uploadError = true
                    })
                }

                if (uploadError) {
                    this.$swal(
                        'Error',
                        `Ukuran max file yang boleh di unggah adalah ${fileSize(maxFileUpload)}`,
                        'error'
                    )
                } else {
                    this.uploadFilesLoading = true
                    this.addFile({
                        slug: this.$route.params.code,
                        attachments: event.target.files
                    })
                        .catch(e => console.log(e))
                        .finally(() => {
                            this.uploadFilesLoading = false
                        })
                }
            },
            onBannerChange (event) {
                this.uploadFilesLoading = true
                this.changeBanner({
                    slug: this.$route.params.code,
                    attachments: event.target.files[0]
                })
                    .then(() => {
                        this.detail.banner = URL.createObjectURL(event.target.files[0])
                    })
                    .catch(e => console.log(e))
                    .finally(() => {
                        this.uploadFilesLoading = false
                    })
            },
            actionJoin () {
                const $this = this
                axios.get('group/join/' + this.$route.params.code).then(function (response) {
                    $this.$swal({
                        imageUrl: require('@/assets/images/vector-success.png'),
                        imageWidth: 400,
                        title: 'Berhasil Join Group',
                        text: response.data.message
                    }).then(function () {
                        $this.fetchDetailGroup()
                    })
                }).catch(function () {
                    $this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                })
            },
            inviteMemberShow () {
                this.inviteMemberGetUsers()
                this.$modal.show('invite-member')
            },
            inviteMemberClose () {
                this.$modal.hide('invite-member')
                this.inviteMember.search = ''
                this.inviteMember.users = []
                this.inviteMember.checkedUsers = []
            },
            inviteMemberGetUsers () {
                this.inviteMember.usersLoading = true
                this.getInviteMemberList({
                    slug: this.$route.params.code,
                    search: this.inviteMember.search
                })
                    .then(data => {
                        this.inviteMember.users = data
                    })
                    .finally(() => {
                        this.inviteMember.usersLoading = false
                    })
            },
            inviteMemberChecklistChange (userId, value) {
                if (value) {
                    this.inviteMember.checkedUsers.push(userId)
                } else {
                    const findIndex = this.inviteMember.checkedUsers.findIndex(i => (i === userId))
                    if (findIndex > -1) {
                        this.inviteMember.checkedUsers.splice(findIndex, 1)
                    }
                }
            },
            inviteMembers () {
                this.inviteMember.loading = true
                this.$store.dispatch('groups/inviteMember', {
                    slug: this.$route.params.code,
                    members: this.inviteMember.checkedUsers
                })
                    .then(res => {
                        this.$swal(
                            'Invited!',
                            'Teman telah ditambahkan sebagai member group.',
                            'success'
                        )
                        this.inviteMemberClose()
                    })
                    .finally(() => {
                        this.inviteMember.loading = false
                    })
            },
            reportGroup () {
                this.$modal.show('report-group')
            },
            closeReportGroup () {
                this.$modal.hide('report-group')
                this.report.data.reason = ''
            },
            submitReportGroup () {
                this.report.loading = true
                this.$store.dispatch('reports/create', {
                    group_id: this.detail.id,
                    reason: this.report.data.reason
                })
                    .then((res) => {
                        this.closeReportGroup()
                        this.$swal(
                            'Success!',
                            'Laporan telah berhasil dikirim',
                            'success'
                        )
                    })
                    .finally(() => {
                        this.report.loading = false
                    })
            }
        }
    }
</script>
